import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import { useMediaQuery } from 'usehooks-ts';

import { IonButton, IonContent, IonFooter, IonHeader, IonSpinner } from '@ionic/react';

import ChevronDownIcon from '@/components/icons/common/ChevronDownIcon.tsx';
import { useCategories } from '@/modules/categories/api/useCategories.ts';
import { ICategory } from '@/modules/categories/types/ICategory.ts';

const CategoryRow = memo(
  ({
    categoriesNestedIds,
    category,
    isMobile,
    level,
    onSelect,
    selected,
  }: {
    categoriesNestedIds: Record<string, string[]>;
    category: ICategory;
    isMobile: boolean;
    level: number;
    onSelect: (category: ICategory) => void;
    selected?: ICategory;
  }) => {
    const [isOpen, setIsOpen] = useState(false);

    const hasChildren = !!category.subcategories?.length;

    useEffect(() => {
      if (selected?.id && categoriesNestedIds[category.id].includes(selected.id)) {
        setIsOpen(true);
      }
    }, [category.id, selected, categoriesNestedIds[category.id]]);

    return (
      <div className={clsx('flex flex-col', isOpen ? '' : '')}>
        <div
          className={clsx(
            'flex h-13 cursor-pointer flex-row items-center justify-between gap-2 rounded-16 p-3',
            selected?.id == category.id && 'bg-gobazar-100',
          )}
          onClick={() => {
            if (hasChildren) {
              setIsOpen((prev) => !prev);
            } else {
              onSelect(category);
            }
          }}
          style={{ paddingLeft: level * (isMobile ? 12 : 24), paddingRight: level * 12 }}
        >
          <p className={clsx('text-16', level == 1 ? 'font-5' : '')}>{category.name}</p>
          {hasChildren && (
            <ChevronDownIcon
              className={clsx(
                'shrink-0 transition-transform duration-300',
                isOpen ? '-rotate-180' : '-rotate-90',
              )}
            />
          )}
        </div>

        <div
          className={clsx(
            'overflow-hidden transition-[max_height]',
            isOpen ? 'max-h-[2500px] duration-300 ease-in' : 'max-h-0 duration-200 ease-out',
          )}
        >
          {category.subcategories?.map((child) => (
            <CategoryRow
              categoriesNestedIds={categoriesNestedIds}
              category={child}
              isMobile={isMobile}
              key={`category-${child.id}`}
              level={level + 1}
              onSelect={onSelect}
              selected={selected}
            />
          ))}
        </div>
      </div>
    );
  },
);

export default function CategoryTreeSelectContent({
  onClose,
  onSelect,
  selected,
}: {
  onClose: () => void;
  onSelect: (category: ICategory) => void;
  selected?: ICategory;
}) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 992px)', {});

  const categoriesQuery = useCategories({
    showCategoriesWithStores: true,
    showDeletedCategories: false,
  });
  const categories = categoriesQuery?.data?.data;

  const nestedIds = useMemo<Record<string, string[]>>(
    () =>
      (categoriesQuery.data?.data || []).reduce<Record<string, string[]>>((cats, cat) => {
        function nestChildren(category: ICategory) {
          const ids: string[] = [];
          category.subcategories?.forEach((childCategory) => {
            nestChildren(childCategory);
            ids.push(...cats[childCategory.id], childCategory.id);
          });
          cats[category.id] = ids;
        }

        nestChildren(cat);
        return cats;
      }, {}),
    [categoriesQuery.data?.data],
  );

  return (
    <>
      <IonHeader
        className={clsx('p-6 pt-8 text-18 font-5 text-neutral-500', isMobile ? 'pl-6' : 'pl-9')}
      >
        {t('categories.choose')}
      </IonHeader>
      <IonContent>
        <div className="flex flex-col gap-2.5 p-6 pl-3 pr-6 pt-0">
          {categoriesQuery?.isPending ? (
            <div className="my-7 flex justify-center">
              <IonSpinner className="h-14 w-14" name="dots" />
            </div>
          ) : (
            categories?.map((category) => (
              <CategoryRow
                categoriesNestedIds={nestedIds}
                category={category}
                isMobile={isMobile}
                key={`category-${category.id}`}
                level={1}
                onSelect={onSelect}
                selected={selected}
              />
            ))
          )}
        </div>
      </IonContent>
      <IonFooter>
        <IonButton className="mx-4 mb-6" expand="block" onClick={onClose}>
          {t('common.close')}
        </IonButton>
      </IonFooter>
    </>
  );
}
